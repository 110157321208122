<script>
import { mapGetters, mapMutations } from 'vuex';
import stepsMixin from '@/mixins/stepsMixin'
export default {
	mixins: [stepsMixin],
	props: ['client'],
	components: {
		SvgIcon: () => import('@/components/SvgIcon'),
	},
	data() {
		return {
			formData: {
				group: '',
				parent_company: '',
				parent_tax_id: '',
				core_asset_bbva: '',
				// Management
				// STRATEGIC POSITIONING
				existence_clarity_focus_track_record: '',
				// RISK MANAGEMENT / FINANCIAL MANAGEMENT
				style_and_tolereance_for_risk: '',
				operational_controls: '',
				// ORGANIZATIONAL EFFECTIVENESS
				quanlity_and_depth: '',
				continuity:'',
				success_viability_flexibility: '',
				// GOVERNANCE
				executive_board_over_sight: '',
				// MANAGEMENT ASSESSMENT
				management_valuation: '',
				management_assessment: ''
			},
		}
	},

	computed: {
		...mapGetters({
			'ratingSelected': 'arceRatings/selected'
		})
	},

	
	methods: {
		calcFill() {
			let fields = Object.keys(this.form)
			if(this.form.multipleGeografia == 'NO') {
				let index = fields.indexOf('comentariosMultipleGeografia')
				fields.splice(index, 1)
			}
			if(this.form.multipleActividadEconomica == 'NO') {
				let index = fields.indexOf('comentariosMultipleActividadEconomica')
				fields.splice(index, 1)
			}
			let points = 100 / fields.length

			let fill = 0

			fields.forEach(f => {
				if(this.form[f]) {
					fill += points
				}
			})

			return Math.round(fill)
		},
		save() {
			let vm = this
			let payload =  {
				key: 'basic',
				form: {...this.formData},
				data: {},
			}
			vm.updateAnalysis(payload)
			this.hideModal('#formBasicModal')
		},
		showModal(id) {
			if(document.querySelectorAll(`${id}`).length > 1) {
				$(`body > ${id}`).remove()
				this.showModal(id)
			} else {
				$(id).appendTo("body").modal('show')

			}
		},
		hideModal(id) {
			if(document.querySelectorAll(`${id}`).length > 1) {
				$(`body > ${id}`).modal('hide')
			}
		},
		closeModal() {
			this.updateFormData()
		},
		updateFormData() {
			this.$set(this, 'formData', {...this.ratingSelected.analysis.basic.form})
		},
		...mapMutations({
			updateAnalysis: 'arceRatings/updateAnalysis'
		}) 
		
	},
	mounted() {
		this.updateFormData()
		$('#formBasicModal').on('hide.bs.modal', this.closeModal)
	},

	beforeDestroy() {
		$('#formBasicModal').off('hide.bs.modal', this.closeModal)
	}
	
}
</script>
<template>
	<div style="">
		<div class="text-right py-2">
			<button class="s-btn s-btn--primary" @click="showModal('#formBasicModal')">
				Edit
			</button>
		</div>
		<div  v-if="formData">
			<div class="s-collapse__header collapsed" data-toggle="collapse" data-target="#c_abr_n_1" aria-expanded="true" aria-controls="c_abr_n_1">
				<div class="d-flex">
					<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>Basic information</span></h6>
				</div>
			</div>
			<div id="c_abr_n_1" class="collapse show" aria-labelledby="headingOne">
				<div class="d-flex flex-wrap">
					<div class="col-4 py-3">
						<p class="m-0">Group</p>
						<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.basic.form.group">
					</div>
					<div class="col-4 py-3">
						<p class="m-0">Parent Company</p>
						<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.basic.form.parent_company">
					</div>
					<div class="col-4 py-3">
						<p class="m-0">Parent Tax ID <SvgIcon name="info"/></p>
						<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.basic.form.parent_tax_id">
					</div>
					<div class="col-4 py-3">
						<p class="m-0">Core Asset BBVA <SvgIcon name="info"/></p>
						<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.basic.form.core_asset_bbva">
					</div>
					
				</div>
			</div>

			<div class="s-collapse__header   mt-2" data-toggle="collapse" data-target="#c_abr_n_2" aria-expanded="true" aria-controls="c_abr_n_2">
				<div class="d-flex">
					<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> Management</span></h6>
				</div>
			</div>
			<div id="c_abr_n_2" class="collapse show" aria-labelledby="headingOne">
				<div class="s-collapse__header   mt-2" data-toggle="collapse" data-target="#sc_abr_n_1" aria-expanded="true" aria-controls="sc_abr_n_1">
					<div class="d-flex">
						<h6 class="title"><svg-icon name="chevrondown" /> <span> STRATEGIC POSITIONING</span></h6>
					</div>
				</div>
				<div id="sc_abr_n_1" class="collapse show" aria-labelledby="headingOne">
					<div class="d-flex flex-wrap" >
	
						<div class="col-4 p-2">
							<label class="d-block m-0">
								<p class="m-0">Existence / Clarity / Focus / Track Record</p>
								<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.basic.form.existence_clarity_focus_track_record">
							</label>
						</div>
					
						
						
					</div>
				</div>

				<div class="s-collapse__header   mt-2" data-toggle="collapse" data-target="#sc_abr_n_2" aria-expanded="true" aria-controls="sc_abr_n_2">
					<div class="d-flex">
						<h6 class="title"><svg-icon name="chevrondown" /> <span> RISK MANAGEMENT / FINANCIAL MANAGEMENT</span></h6>
					</div>
				</div>
				<div id="sc_abr_n_2" class="collapse show" aria-labelledby="headingOne">
					<div class="d-flex flex-wrap" >
	
						<div class="col-4 p-2">
							<label class="d-block m-0">
								<p class="m-0">Style And Tolerance For Risk</p>
								<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.basic.form.style_and_tolereance_for_risk">
							</label>
						</div>
						<div class="col-4 p-2">
							<label class="d-block m-0">
								<p class="m-0">Operational Controls</p>
								<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.basic.form.operational_controls">
							</label>
						</div>
						
					</div>
				</div>

				<div class="s-collapse__header   mt-2" data-toggle="collapse" data-target="#sc_abr_n_3" aria-expanded="true" aria-controls="sc_abr_n_3">
					<div class="d-flex">
						<h6 class="title"><svg-icon name="chevrondown" /> <span> ORGANIZATIONAL EFFECTIVENESS</span></h6>
					</div>
				</div>
				<div id="sc_abr_n_3" class="collapse show" aria-labelledby="headingOne">
					<div class="d-flex flex-wrap" >
	
						<div class="col-4 p-2">
							<label class="d-block m-0">
								<p class="m-0">Quality And Depth</p>
								<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.basic.form.quanlity_and_depth">
							</label>
						</div>
						<div class="col-4 p-2">
							<label class="d-block m-0">
								<p class="m-0">Continuity</p>
								<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.basic.form.continuity">
							</label>
						</div>
						<div class="col-4 p-2">
							<label class="d-block m-0">
								<p class="m-0">Success / Viability / Flexibility</p>
								<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.basic.form.success_viability_flexibility">
							</label>
						</div>
						
					</div>
				</div>
				
				<div class="s-collapse__header   mt-2" data-toggle="collapse" data-target="#sc_abr_n_4" aria-expanded="true" aria-controls="sc_abr_n_4">
					<div class="d-flex">
						<h6 class="title"><svg-icon name="chevrondown" /> <span> GOVERNANCE</span></h6>
					</div>
				</div>
				<div id="sc_abr_n_4" class="collapse show" aria-labelledby="headingOne">
					<div class="d-flex flex-wrap" >
	
						<div class="col-4 p-2">
							<label class="d-block m-0">
								<p class="m-0">Executive Board Over - Sight</p>
								<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.basic.form.executive_board_over_sight">
							</label>
						</div>
					</div>
				</div>
				
				<div class="s-collapse__header   mt-2" data-toggle="collapse" data-target="#sc_abr_n_5" aria-expanded="true" aria-controls="sc_abr_n_5">
					<div class="d-flex">
						<h6 class="title"><svg-icon name="chevrondown" /> <span> MANAGEMENT ASSESSMENT</span></h6>
					</div>
				</div>
				<div id="sc_abr_n_5" class="collapse show" aria-labelledby="headingOne">
					<div class="d-flex flex-wrap" >
	
						<div class="col-4 p-2">
							<label class="d-block m-0">
								<p class="m-0">Management Valuation</p>
								<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.basic.form.management_valuation">
							</label>
						</div>
					</div>
					<div class="d-flex flex-wrap" >
						<div class="col-4 p-2">
							<label class="d-block m-0">
								<p class="m-0">Management Assessment</p>
								<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.basic.form.management_assessment">
							</label>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- ============================================ -->
		<!-- ============================================ -->
		<!-- ============================================ -->
		<!-- ============================================ -->
		<!-- ============================================ -->
		<div class="modal s-modal fade" style="font-size: 0.8em" id="formBasicModal" tabindex="-1" role="dialog" aria-labelledby="formBasicModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-xl " role="document">
				<div class="modal-content">
				<div class="modal-header p-0">
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
			<div class="s-collapse__header collapsed" data-toggle="collapse" data-target="#c_abr_m_1" aria-expanded="true" aria-controls="c_abr_m_1">
				<div class="d-flex">
					<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>Basic information</span></h6>
				</div>
			</div>
			<div id="c_abr_m_1" class="collapse show" aria-labelledby="headingOne">
				<div class="d-flex flex-wrap">
					<div class="col-4 py-3">
						<p class="m-0">Group</p>
						<input class="form-control form-control--disabled" disabled type="text" v-model="formData.group">
					</div>
					<div class="col-4 py-3">
						<p class="m-0">Parent Company</p>
						<input class="form-control form-control--disabled" disabled type="text" v-model="formData.parent_company">
					</div>
					<div class="col-4 py-3">
						<p class="m-0">Parent Tax ID <SvgIcon name="info"/></p>
						<input class="form-control form-control--disabled" disabled type="text" v-model="formData.parent_tax_id">
					</div>
					<div class="col-4 py-3">
						<p class="m-0">Core Asset BBVA <SvgIcon name="info"/></p>
						<input class="form-control form-control--disabled" disabled type="text" v-model="formData.core_asset_bbva">
					</div>
					
				</div>
			</div>

			<div class="s-collapse__header   mt-2" data-toggle="collapse" data-target="#c_abr_m_2" aria-expanded="true" aria-controls="c_abr_m_2">
				<div class="d-flex">
					<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> Management</span></h6>
				</div>
			</div>
			<div id="c_abr_m_2" class="collapse show" aria-labelledby="headingOne">
				<div class="s-collapse__header   mt-2" data-toggle="collapse" data-target="#sc_abr_m_1" aria-expanded="true" aria-controls="sc_abr_m_1">
					<div class="d-flex">
						<h6 class="title"><svg-icon name="chevrondown" /> <span> STRATEGIC POSITIONING</span></h6>
					</div>
				</div>
				<div id="sc_abr_m_1" class="collapse show" aria-labelledby="headingOne">
					<div class="d-flex flex-wrap" >
	
						<div class="col-4 p-2">
							<label class="d-block m-0">
								<p class="m-0">Existence / Clarity / Focus / Track Record</p>
								<input class="form-control" type="text" v-model="formData.existence_clarity_focus_track_record">
							</label>
						</div>
					
						
						
					</div>
				</div>

				<div class="s-collapse__header   mt-2" data-toggle="collapse" data-target="#sc_abr_m_2" aria-expanded="true" aria-controls="sc_abr_m_2">
					<div class="d-flex">
						<h6 class="title"><svg-icon name="chevrondown" /> <span> RISK MANAGEMENT / FINANCIAL MANAGEMENT</span></h6>
					</div>
				</div>
				<div id="sc_abr_m_2" class="collapse show" aria-labelledby="headingOne">
					<div class="d-flex flex-wrap" >
	
						<div class="col-4 p-2">
							<label class="d-block m-0">
								<p class="m-0">Style And Tolerance For Risk</p>
								<input class="form-control" type="text" v-model="formData.style_and_tolereance_for_risk">
							</label>
						</div>
						<div class="col-4 p-2">
							<label class="d-block m-0">
								<p class="m-0">Operational Controls</p>
								<input class="form-control" type="text" v-model="formData.operational_controls">
							</label>
						</div>
						
					</div>
				</div>

				<div class="s-collapse__header   mt-2" data-toggle="collapse" data-target="#sc_abr_m_3" aria-expanded="true" aria-controls="sc_abr_m_3">
					<div class="d-flex">
						<h6 class="title"><svg-icon name="chevrondown" /> <span> ORGANIZATIONAL EFFECTIVENESS</span></h6>
					</div>
				</div>
				<div id="sc_abr_m_3" class="collapse show" aria-labelledby="headingOne">
					<div class="d-flex flex-wrap" >
	
						<div class="col-4 p-2">
							<label class="d-block m-0">
								<p class="m-0">Quality And Depth</p>
								<input class="form-control" type="text" v-model="formData.quanlity_and_depth">
							</label>
						</div>
						<div class="col-4 p-2">
							<label class="d-block m-0">
								<p class="m-0">Continuity</p>
								<input class="form-control" type="text" v-model="formData.continuity">
							</label>
						</div>
						<div class="col-4 p-2">
							<label class="d-block m-0">
								<p class="m-0">Success / Viability / Flexibility</p>
								<input class="form-control" type="text" v-model="formData.success_viability_flexibility">
							</label>
						</div>
						
					</div>
				</div>
				
				<div class="s-collapse__header   mt-2" data-toggle="collapse" data-target="#sc_abr_m_4" aria-expanded="true" aria-controls="sc_abr_m_4">
					<div class="d-flex">
						<h6 class="title"><svg-icon name="chevrondown" /> <span> GOVERNANCE</span></h6>
					</div>
				</div>
				<div id="sc_abr_m_4" class="collapse show" aria-labelledby="headingOne">
					<div class="d-flex flex-wrap" >
	
						<div class="col-4 p-2">
							<label class="d-block m-0">
								<p class="m-0">Executive Board Over - Sight</p>
								<input class="form-control" type="text" v-model="formData.executive_board_over_sight">
							</label>
						</div>
					</div>
				</div>
				
				<div class="s-collapse__header   mt-2" data-toggle="collapse" data-target="#sc_abr_m_5" aria-expanded="true" aria-controls="sc_abr_m_5">
					<div class="d-flex">
						<h6 class="title"><svg-icon name="chevrondown" /> <span> MANAGEMENT ASSESSMENT</span></h6>
					</div>
				</div>
				<div id="sc_abr_m_5" class="collapse show" aria-labelledby="headingOne">
					<div class="d-flex flex-wrap" >
	
						<div class="col-4 p-2">
							<label class="d-block m-0">
								<p class="m-0">Management Valuation</p>
								<input class="form-control" type="text" v-model="formData.management_valuation">
							</label>
						</div>
					</div>
					<div class="d-flex flex-wrap" >
						<div class="col-4 p-2">
							<label class="d-block m-0">
								<p class="m-0">Management Assessment</p>
								<input class="form-control" type="text" v-model="formData.management_assessment">
							</label>
						</div>
					</div>
				</div>
			</div>
					
				</div>
				<div class="modal-footer">
					<button type="button" class="s-btn s-btn--outline" data-dismiss="modal">Cancelar</button>
					<button type="button" class="s-btn s-btn--primary" data-dismiss="modal" @click="save">Guardar</button>
				</div>
				</div>
			</div>
		</div>
	</div>
</template>
